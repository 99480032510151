import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Post from '../Post';
import image1 from '../assets/pdf/p1.jpg';
import image2 from '../assets/pdf/p2.png';
import image3 from '../assets/pdf/p3.png';
import image4 from '../assets/pdf/p1.jpg';
import image5 from '../assets/pdf/p2.png';
import image6 from '../assets/pdf/p3.png';
import image7 from '../assets/pdf/p2.png';

const postData = [
  {
    url: 'https://ilabs.uw.edu/sites/default/files/Zhao_Kuhl_2016.pdf',
    src: image1,
    alt: 'Post',
    title: 'Musical intervention enhances infants’ neural processing of temporal structure in music and speech',
  },
  {
    url: 'https://www.scirp.org/journal/paperinformation?paperid=98060',
    src: image2,
    alt: 'Post',
    title: 'The Positive Influence of Music on the Human Brain',
  },
  {
    url: 'https://www.psychologytoday.com/us/blog/brain-candy/201201/no-really-teach-your-toddler-perfect-pitch',
    src: image3,
    alt: 'Post',
    title: 'No Really: Teach Your Toddler Perfect Pitch',
  },
  {
    url: 'https://www.nature.com/articles/nrn1787',
    src: image4,
    alt: 'Post',
    title: 'Critical Period Plasticity in Local Cortical Circuits',
  },
  {
    url: 'https://www.mdpi.com/2227-7390/10/3/349',
    src: image5,
    alt: 'Post',
    title: 'The Effect of Music Listening on EEG Functional Connectivity of Brain',
  },
  {
    url: 'https://pubmed.ncbi.nlm.nih.gov/16521731/',
    src: image6,
    alt: 'Post',
    title: 'Absolute Pitch Among American and Chinese Conservatory Students',
  },
  {
    url: 'https://www.frontiersin.org/journals/neuroscience/articles/10.3389/fnins.2013.00227/full',
    src: image7,
    alt: 'Post',
    title: 'The Relationship Between the Age of Onset of Musical Training and Rhythm',
  },
];

export default function PostSlider1() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
