/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

// Import components
import Card from "../Card";
// import FunFact from '../FunFact';

import "./css/heroFile.css";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import PortfolioSlider from "../Slider/PortfolioSlider";
import PostSlider from "../Slider/PostSlider";
// import TestimonialSlider from "../Slider/TestimonialSlider";
import TeamSlider from "../Slider/TeamSlider";
// import VideoModal from "../VideoModal";
// import TimelineSlider from "../Slider/TimelineSlider";
import { pageTitle } from "../../helper";
import PricingTableList from "../PricingTable/PricingTableList";
import TeamSlider2 from "../Slider/TeamSlider2";

// Gif Imports
import rocket from "../assets/gif/Rocket.gif";
import surf from "../assets/gif/Parachute.gif";
import intoTheChair from "../assets/gif/Fly into chair.gif";
import babyCrawl from "../assets/gif/pianokeys.gif";
import PostSlider1 from "../Slider/PostSlider1";
// import Footer from "../Footer";
import Footer2 from "./Footer2";
// import { PDFViewer } from "@react-pdf/renderer";

// Importing the pdfScreenshot 

// import pdfScreenshot from "../assets/thumb/pdfscreenshot.jpg"
// import PdfViewer from "./PdfViewer";
// import PdfContent from "./PdfContent";


// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'npm:pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();





// Gif function

// const ImageComponent = () => {
//   const [gifLoaded, setGifLoaded] = useState(false);

//   const handleGifLoad = () => {
//     setGifLoaded(true);
//   };
// };

// Constants for Hero Social Links
// const heroSocialLinks = [
//   { name: "Behance", links: "/" },
//   { name: "Twitter", links: "/" },
// ];

// Constants for FunFact Data
const funFactData = [
  { factNumber: "400%", title: "Grows from birth through age 7" },
  {
    factNumber: "3",
    title: "Is learning at the fastest possible rate through age 3",
  },
  {
    factNumber: "Thousands",
    title: "Is making synaptic connections per second (spinning counter)",
  },
  {
    factNumber: "Enhanced",
    title: "Can be magnificently enhanced through pitch recognition",
  },
];

// Constants for Portfolio Data
const portfolioData = [
  {
    title: "Learning Is Actually Automatic",
    subtitle: "See Details",
    href: "/portfolio/1",
    src: "/images/graph111.png",
  },
  {
    title: "Theta Brainwaves",
    subtitle: "See Details",
    href: "/portfolio/2",
    src: "/images/portfolio_2.gif",
  },
  {
    title: "A Perfect Presentation!",
    subtitle: "See Details",
    href: "/portfolio/3",
    src: "/images/portfolio_3.gif",
  },
];

export default function Home() {
  // Set the page title
  useEffect(() => {
    pageTitle("Home");
    window.scrollTo(0, 0);
  }, []);

  return (

    <div className="body">
      {/* Hero Section */}
      <Hero
        title="Science Based <br/>Learning Through Music"
        subtitle="Supercharge Your Child’s Intelligence"
        // btnText="Get a Quote"
        // btnLink="/contact"
        // scrollDownId="#service"
        // socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
        bgVideosrc="video/video-4.mp4"
      />

      {/* FunFact Section */}
      {/* <div className="container" style={{ background: '#181818' }}> */}

      {/* <FunFact
          variant="cs-type1"
          title="Your Baby's Brain"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium."
          data={funFactData}
        /> */}
      {/* baby on rocket gif  */}

      {/* </div> */}
      <div className="babyContainer" style={{ marginTop: "0px", top: "0px" }}>
        <div
          className="FunFactsText cs-secondary_font"
          style={{
            backgroundColor: "#181818",
            color: "white",
            height: "600px",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              fontSize: "75px",
              textTransform: "uppercase",
            }}
          >
            your baby&apos;S brain
          </h1>

          <ul
            style={{
              display: "grid",
              margin: "0",
              padding: "0",
              listStyleType: "none",
              fontSize: "40px",
              gap: "20px", // Consistent spacing between list items
              lineHeight: "1",
              textAlign: "center",
              // Consistent line-height for all text
            }}
          >
            <li style={{ fontSize: "34px", top: "-50px" }}>
              Grows <span style={{ fontSize: "75px" }}>400%</span> from birth
              through age <span style={{ fontSize: "75px" }}> 7</span>
            </li>
            <li style={{ fontSize: "34px" }}>
              Is learning at the fastest possible rate through age{" "}
              <span style={{ fontSize: "75px" }}>3</span>
            </li>
            <li style={{ fontSize: "34px" }}>
              Is making <span style={{ fontSize: "75px" }}>500,000</span>{" "}
              synaptic connections per second
            </li>
          </ul>

          <h1
            style={{
              fontSize: "60px",
              marginTop: "-10px", // This will push it to the bottom
              textAlign: "center",
            }}
          >
            Can be magnificently enhanced through pitch recognition
          </h1>
          <img
            src={rocket}
            alt="rocket"
            className="item"
            style={{
              position: "absolute",
              width: "200px",
              height: "200px",
              animation: "diagonal-move-anim 10s linear infinite", // Continuous animation
              zIndex: "10",
              transformOrigin: "bottom left", // Origin at bottom left
            }}
          />
        </div>
      </div>

      {/* Service Section */}
      <img
        src={intoTheChair}
        alt="rocket"
        className="top-left-diagonal"
        y
        style={{
          position: "absolute",
          width: "400px",
          height: "400px",
          right: "0%",
          marginTop: "100px",
          zIndex: "10",
          transformOrigin: "top right", // Origin at top left
          // animation: 'top-to-bottom 10s linear infinite', // Apply top-to-bottom animation
        }}
      />
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Why Learn Musical Pitch?"
                subtitle="You can do more"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Enhances Language Acquisition"
                    link="/service/1"
                    src="/images/service_1.jpeg"
                    alt="Service"
                    style={{ height: '300px' }}
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Double Their Synaptic Architecture"
                    link="/service/2"
                    src="/images/service_2.gif"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Give Them The Gift Of Music"
                    link="/service/3"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="It Is Easy & Automatic"
                    link="/service/4"
                    src="/images/service_4.gif"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container-easiest">
        <Spacing lg="90" md="45" />
          <SectionHeading
            title="The Easiest And Most Advanced Child Learning System On Earth!"
            subtitle=""
            variant="cs-style1 text-center white-text"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <Div className="home-portfolio">
          <img
            src={surf}
            alt="rocket"
            className="top-left-diagonal"
            style={{
              position: "absolute",
              width: "400px",
              height: "400px",
              right: "50%",
              zIndex: "10",
              transformOrigin: "top left", // Origin at top left
              animation: "top-to-bottom 15s linear infinite", // Apply top-to-bottom animation
            }}
          />
          <PortfolioSlider data={portfolioData} />
        </Div>
      </Div>
      <Spacing lg="70" md="70" />
      <Spacing lg="70" md="70" />
      <Spacing lg="70" md="70" />
      {/* Awards Section */}
      {/* <PDFViewer />
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4"> */}
              {/* <SectionHeading
                  title="We Get Multiple Awards"
                  subtitle="Our Awards"
                  variant="cs-style1"
                /> */}

              {/* <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}

      {/* <PdfContent pdfUrl={pdfUrl} />  */}
      {/* Video Block Section */}

      {/* <Spacing lg="130" md="70" /> */}
      <Div className="container2" >
        <Spacing lg="70" md="70" />
        <h2 className="cs-font_100 cs-m0 text-center cs-line_height_4" style={{ fontSize: '75px', color: 'black' }}> <a href="https://drive.google.com/file/d/1toEcZShPHxcUxHR5XtenyIJDRdEyzFR5/view?usp=sharing">
          White Paper: Infant Neuroplasticity & Pitch Acquisition
        </a>

        </h2>
        <Spacing lg="70" md="70" />
        {/* <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        /> */}
        {/* <img src={pdfScreenshot} alt="" />  */}
      </Div>

      {/* Team Section */}
      <Spacing lg="70" md="70" />
      <Spacing lg="70" md="70" />
      <Spacing lg="70" md="70" />
      <Spacing lg="145" md="80" />
      <Div className="team-sec">
        <Div className="container">
          <img
            src={babyCrawl}
            alt="rocket"
            className="top-left-diagonal"
            style={{
              position: "absolute",
              width: "1200px",
              height: "1200px",
              zIndex: 10,
              transformOrigin: "top left", // Set origin at top left
              transform: "translate(350px, -200px)", // Start at the top (y: 0) and move to bottom (100vh)
              animation: "left-to-right 1s linear infinite", // Apply animation (if desired)
            }}
          />

          <SectionHeading
            title={`<span style="display: block; text-align: center; line-height: 0.1; margin-bottom: -10px;">Absolute Pitch - Unlocking Musical Genius`}
            subtitle=""
            variant="cs-style1"
            style={{ justifyContent: "center", textAlign: "center" }}
          />
          <Spacing lg="85" md="45" />
          <Spacing lg="85" md="45" />
          <Spacing lg="85" md="45" />
          {/* <Spacing lg="85" md="45" />
          <Spacing lg="85" md="45" /> */}
          <TeamSlider style={{ marginTop: "10px" }} />
        </Div>
      </Div>

      {/* Testimonial Section */}
      {/* <TestimonialSlider /> */}


      <TeamSlider2 />




      {/* Blog Section */}
      {/* <Spacing lg="150" md="80" /> */}
      <Div className="blog-sec">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Scientific validation of HOW and WHY this method works!"
              // subtitle="How and Why?"
              // btnText="View More Blogs"
              // btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <PostSlider />
              <PostSlider1 />
            </Div>
          </Div>
        </Div>
      </Div>



      {/* Moving Text Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Teaching Pitch Recognition Has Never Been Possible Before -" />
      <Spacing lg="105" md="70" />

      {/* LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>

      {/* CTA Section */}
      <Div className="container">
        <Cta
          title="This Magical, Effortless Ability to Learn Unconsciously is Fleeting. Get Started Today!"
          btnText=" "
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>

      {/* Pricing Table Section */}
      <PricingTableList />
      <Footer2 />
    </div>
  );
}






// // Code backup

// import React, { useState, useEffect } from "react";

// // Import components
// import Card from "../Card";
// // import FunFact from '../FunFact';

// import "./css/heroFile.css";
// import Hero from "../Hero";
// import Div from "../Div";
// import SectionHeading from "../SectionHeading";
// import Spacing from "../Spacing";
// import Cta from "../Cta";
// import LogoList from "../LogoList";
// import MovingText from "../MovingText";
// import PortfolioSlider from "../Slider/PortfolioSlider";
// import PostSlider from "../Slider/PostSlider";
// import TestimonialSlider from "../Slider/TestimonialSlider";
// import TeamSlider from "../Slider/TeamSlider";
// import VideoModal from "../VideoModal";
// import TimelineSlider from "../Slider/TimelineSlider";
// import { pageTitle } from "../../helper";
// import PricingTableList from "../PricingTable/PricingTableList";
// import TeamSlider2 from "../Slider/TeamSlider2";

// // Gif Imports
// import rocket from "../assets/gif/Rocket.gif";
// import surf from "../assets/gif/Parachute.gif";
// import intoTheChair from "../assets/gif/Fly into chair.gif";
// import babyCrawl from "../assets/gif/pianokeys.gif";
// import PostSlider1 from "../Slider/PostSlider1";
// import Footer from "../Footer";
// import Footer2 from "./Footer2";
// import { PDFViewer } from "@react-pdf/renderer";

// // Importing the pdfScreenshot 

// import pdfScreenshot from "../assets/thumb/pdfscreenshot.jpg"
// // import PdfViewer from "./PdfViewer";
// // import PdfContent from "./PdfContent";


// // import { pdfjs } from 'react-pdf';
// // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// //   'npm:pdfjs-dist/build/pdf.worker.min.mjs',
// //   import.meta.url,
// // ).toString();





// // Gif function

// const ImageComponent = () => {
//   const [gifLoaded, setGifLoaded] = useState(false);

//   const handleGifLoad = () => {
//     setGifLoaded(true);
//   };
// };

// // Constants for Hero Social Links
// // const heroSocialLinks = [
// //   { name: "Behance", links: "/" },
// //   { name: "Twitter", links: "/" },
// // ];

// // Constants for FunFact Data
// const funFactData = [
//   { factNumber: "400%", title: "Grows from birth through age 7" },
//   {
//     factNumber: "3",
//     title: "Is learning at the fastest possible rate through age 3",
//   },
//   {
//     factNumber: "Thousands",
//     title: "Is making synaptic connections per second (spinning counter)",
//   },
//   {
//     factNumber: "Enhanced",
//     title: "Can be magnificently enhanced through pitch recognition",
//   },
// ];

// // Constants for Portfolio Data
// const portfolioData = [
//   {
//     title: "Learning Is Actually Automatic",
//     subtitle: "See Details",
//     href: "/portfolio/1",
//     src: "/images/graph111.png",
//   },
//   {
//     title: "Theta Brainwaves",
//     subtitle: "See Details",
//     href: "/portfolio/2",
//     src: "/images/portfolio_2.gif",
//   },
//   {
//     title: "A Perfect Presentation!",
//     subtitle: "See Details",
//     href: "/portfolio/3",
//     src: "/images/portfolio_3.gif",
//   },
// ];

// export default function Home() {
//   // Set the page title
//   useEffect(() => {
//     pageTitle("Home");
//     window.scrollTo(0, 0);
//   }, []);

//   return (

//     <div className="body">
//       {/* Hero Section */}
//       <Hero
//         title="Science Based <br/>Learning Through Music"
//         subtitle="Supercharge Your Child’s Intelligence"
//         // btnText="Get a Quote"
//         // btnLink="/contact"
//         // scrollDownId="#service"
//         // socialLinksHeading="Follow Us"
//         // heroSocialLinks={heroSocialLinks}
//         bgImageUrl="/images/hero_bg.jpeg"
//         bgVideosrc="video/video-4.mp4"
//       />

//       {/* FunFact Section */}
//       {/* <div className="container" style={{ background: '#181818' }}> */}

//       {/* <FunFact
//           variant="cs-type1"
//           title="Your Baby's Brain"
//           subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium."
//           data={funFactData}
//         /> */}
//       {/* baby on rocket gif  */}

//       {/* </div> */}
//       <div className="babyContainer" style={{ marginTop: "0px", top: "0px" }}>
//         <div
//           className="FunFactsText cs-secondary_font"
//           style={{
//             backgroundColor: "#181818",
//             color: "white",
//             height: "600px",
//             width: "100vw",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             padding: "20px",
//           }}
//         >
//           <h1
//             style={{
//               textAlign: "center",
//               fontSize: "75px",
//               textTransform: "uppercase",
//             }}
//           >
//             your baby&apos;S brain
//           </h1>

//           <ul
//             style={{
//               display: "grid",
//               margin: "0",
//               padding: "0",
//               listStyleType: "none",
//               fontSize: "40px",
//               gap: "20px", // Consistent spacing between list items
//               lineHeight: "1",
//               textAlign: "center",
//               // Consistent line-height for all text
//             }}
//           >
//             <li style={{ fontSize: "34px", top: "-50px" }}>
//               Grows <span style={{ fontSize: "75px" }}>400%</span> from birth
//               through age <span style={{ fontSize: "75px" }}> 7</span>
//             </li>
//             <li style={{ fontSize: "34px" }}>
//               Is learning at the fastest possible rate through age{" "}
//               <span style={{ fontSize: "75px" }}>3</span>
//             </li>
//             <li style={{ fontSize: "34px" }}>
//               Is making <span style={{ fontSize: "75px" }}>500,000</span>{" "}
//               synaptic connections per second
//             </li>
//           </ul>

//           <h1
//             style={{
//               fontSize: "60px",
//               marginTop: "-10px", // This will push it to the bottom
//               textAlign: "center",
//             }}
//           >
//             Can be magnificently enhanced through pitch recognition
//           </h1>
//           <img
//             src={rocket}
//             alt="rocket"
//             className="item"
//             style={{
//               position: "absolute",
//               width: "200px",
//               height: "200px",
//               animation: "diagonal-move-anim 10s linear infinite", // Continuous animation
//               zIndex: "10",
//               transformOrigin: "bottom left", // Origin at bottom left
//             }}
//           />
//         </div>
//       </div>

//       {/* Service Section */}
//       <img
//         src={intoTheChair}
//         alt="rocket"
//         className="top-left-diagonal"
//         y
//         style={{
//           position: "absolute",
//           width: "400px",
//           height: "400px",
//           right: "0%",
//           marginTop: "100px",
//           zIndex: "10",
//           transformOrigin: "top right", // Origin at top left
//           // animation: 'top-to-bottom 10s linear infinite', // Apply top-to-bottom animation
//         }}
//       />
//       <Spacing lg="150" md="80" />
//       <Div id="service">
//         <Div className="container">
//           <Div className="row">
//             <Div className="col-xl-4">
//               <SectionHeading
//                 title="Why Learn Musical Pitch?"
//                 subtitle="You can do more"
//                 btnText="See All Services"
//                 btnLink="/service"
//               />
//               <Spacing lg="90" md="45" />
//             </Div>
//             <Div className="col-xl-8">
//               <Div className="row">
//                 <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
//                 <Div className="col-lg-3 col-sm-6">
//                   <Card
//                     title="Enhances Language Acquisition"
//                     link="/service/1"
//                     src="/images/service_1.jpeg"
//                     alt="Service"
//                     style={{ height: '300px' }}
//                   />
//                   <Spacing lg="0" md="30" />
//                 </Div>
//                 <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
//                 <Div className="col-lg-3 col-sm-6">
//                   <Card
//                     title="Double Their Synaptic Architecture"
//                     link="/service/2"
//                     src="/images/service_2.gif"
//                     alt="Service"
//                   />
//                   <Spacing lg="0" md="30" />
//                 </Div>
//                 <Div className="col-lg-3 col-sm-6">
//                   <Card
//                     title="Give Them The Gift Of Music"
//                     link="/service/3"
//                     src="/images/service_3.jpeg"
//                     alt="Service"
//                   />
//                   <Spacing lg="0" md="30" />
//                 </Div>
//                 <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
//                 <Div className="col-lg-3 col-sm-6">
//                   <Card
//                     title="It Is Easy & Automatic"
//                     link="/service/4"
//                     src="/images/service_4.gif"
//                     alt="Service"
//                   />
//                   <Spacing lg="0" md="30" />
//                 </Div>
//                 <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
//               </Div>
//             </Div>
//           </Div>
//         </Div>
//       </Div>

//       {/* Portfolio Section */}
//       <Spacing lg="150" md="50" />
//       <Div>
//         <Div className="container-easiest">
//         <Spacing lg="90" md="45" />
//           <SectionHeading
//             title="The Easiest And Most Advanced Child Learning System On Earth!"
//             subtitle=""
//             variant="cs-style1 text-center white-text"
//           />
//           <Spacing lg="90" md="45" />
//         </Div>
//         <Div className="home-portfolio">
//           <img
//             src={surf}
//             alt="rocket"
//             className="top-left-diagonal"
//             style={{
//               position: "absolute",
//               width: "400px",
//               height: "400px",
//               right: "50%",
//               zIndex: "10",
//               transformOrigin: "top left", // Origin at top left
//               animation: "top-to-bottom 15s linear infinite", // Apply top-to-bottom animation
//             }}
//           />
//           <PortfolioSlider data={portfolioData} />
//         </Div>
//       </Div>
//       <Spacing lg="70" md="70" />
//       <Spacing lg="70" md="70" />
//       <Spacing lg="70" md="70" />
//       {/* Awards Section */}
//       {/* <PDFViewer />
//       <Spacing lg="150" md="80" />
//       <Div className="cs-shape_wrap_2">
//         <Div className="cs-shape_2" />
//         <Div className="container">
//           <Div className="row">
//             <Div className="col-xl-4"> */}
//               {/* <SectionHeading
//                   title="We Get Multiple Awards"
//                   subtitle="Our Awards"
//                   variant="cs-style1"
//                 /> */}

//               {/* <Spacing lg="90" md="45" />
//             </Div>
//             <Div className="col-xl-7 offset-xl-1">
//               <TimelineSlider />
//             </Div>
//           </Div>
//         </Div>
//       </Div> */}

//       {/* <PdfContent pdfUrl={pdfUrl} />  */}
//       {/* Video Block Section */}

//       {/* <Spacing lg="130" md="70" /> */}
//       <Div className="container2" >
//         <Spacing lg="70" md="70" />
//         <h2 className="cs-font_100 cs-m0 text-center cs-line_height_4" style={{ fontSize: '75px', color: 'black' }}> <a href="https://drive.google.com/file/d/1toEcZShPHxcUxHR5XtenyIJDRdEyzFR5/view?usp=sharing">
//           White Paper: Infant Neuroplasticity & Pitch Acquisition
//         </a>

//         </h2>
//         <Spacing lg="70" md="70" />
//         {/* <VideoModal
//           videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
//           bgUrl="/images/video_bg.jpeg"
//         /> */}
//         {/* <img src={pdfScreenshot} alt="" />  */}
//       </Div>

//       {/* Team Section */}
//       <Spacing lg="70" md="70" />
//       <Spacing lg="70" md="70" />
//       <Spacing lg="70" md="70" />
//       <Spacing lg="145" md="80" />
//       <Div className="team-sec">
//         <Div className="container">
//           <img
//             src={babyCrawl}
//             alt="rocket"
//             className="top-left-diagonal"
//             style={{
//               position: "absolute",
//               width: "1200px",
//               height: "1200px",
//               zIndex: 10,
//               transformOrigin: "top left", // Set origin at top left
//               transform: "translate(350px, -200px)", // Start at the top (y: 0) and move to bottom (100vh)
//               animation: "left-to-right 1s linear infinite", // Apply animation (if desired)
//             }}
//           />

//           <SectionHeading
//             title={`<span style="display: block; text-align: center; line-height: 0.1; margin-bottom: -10px;">Absolute Pitch - Unlocking Musical Genius`}
//             subtitle=""
//             variant="cs-style1"
//             style={{ justifyContent: "center", textAlign: "center" }}
//           />
//           <Spacing lg="85" md="45" />
//           <Spacing lg="85" md="45" />
//           <Spacing lg="85" md="45" />
//           {/* <Spacing lg="85" md="45" />
//           <Spacing lg="85" md="45" /> */}
//           <TeamSlider style={{ marginTop: "10px" }} />
//         </Div>
//       </Div>

//       {/* Testimonial Section */}
//       {/* <TestimonialSlider /> */}


//       <TeamSlider2 />




//       {/* Blog Section */}
//       {/* <Spacing lg="150" md="80" /> */}
//       <Div className="blog-sec">
//         <Div className="container">
//           <Div className="row">
//             <Div className="col-xl-4">
//               <SectionHeading
//                 title="Scientific validation of HOW and WHY this method works!"
//               // subtitle="How and Why?"
//               // btnText="View More Blogs"
//               // btnLink="/blog"
//               />
//               <Spacing lg="90" md="45" />
//             </Div>
//             <Div className="col-xl-7 offset-xl-1">
//               <PostSlider />
//               <PostSlider1 />
//             </Div>
//           </Div>
//         </Div>
//       </Div>



//       {/* Moving Text Section */}
//       <Spacing lg="125" md="70" />
//       <MovingText text="Teaching Pitch Recognition Has Never Been Possible Before -" />
//       <Spacing lg="105" md="70" />

//       {/* LogoList Section */}
//       <Div className="container">
//         <LogoList />
//       </Div>

//       {/* CTA Section */}
//       <Div className="container">
//         <Cta
//           title="This Magical, Effortless Ability to Learn Unconsciously is Fleeting. Get Started Today!"
//           btnText=" "
//           btnLink="/contact"
//           bgSrc="/images/cta_bg.jpeg"
//         />
//       </Div>

//       {/* Pricing Table Section */}
//       <PricingTableList />
//       <Footer2 />
//     </div>
//   );
// }
