import React, { useEffect } from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Post from '../Post';
import image1 from '../assets/thumb/t1.jpg';
import image2 from '../assets/thumb/t2.jpg';
import image3 from '../assets/thumb/t3.jpg';
import image4 from '../assets/thumb/t4.jpg';
import image5 from '../assets/thumb/t5.jpg';
import image6 from '../assets/thumb/t6.jpg';
import image7 from '../assets/thumb/t8.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const postData = [
  {
    url: 'https://rumble.com/embed/v4py4ak/?pub=2seqc6',
    src: image1,
    alt: 'Post 1',
    title: 'Neurology of Early Learning',
  },
  {
    url: 'https://rumble.com/embed/v4pk573/?pub=2seqc6',
    src: image2,
    alt: 'Post 2',
    title: 'Theta Brainwaves ALONE Facilitate Learning',
  },
  {
    url: 'https://rumble.com/embed/v4py4ak/?pub=2seqc6',
    src: image3,
    alt: 'Post 3',
    title: 'It Is Now Possible To Give ANY Child The Superpower of Musical Pitch',
  },
  {
    url: 'https://rumble.com/embed/v3fjntc/?pub=2seqc6',
    src: image4,
    alt: 'Post 4',
    title: 'The Link Between Absolute Pitch And Tonal Languages',
  },
  {
    url: 'https://rumble.com/embed/v3pb2tp/?pub=2seqc6',
    src: image5,
    alt: 'Post 5',
    title: `How We Upload Musical Pitch into Your Child's Brain`,
  },
  {
    url: 'https://rumble.com/embed/v3pb2ay/?pub=2seqc6',
    src: image6,
    alt: 'Post 8',
    title: 'Child Learning',
  },
  {
    url: 'https://rumble.com/embed/v4pk3ve/?pub=2seqc6',
    src: image7,
    alt: 'Post 7',
    title: 'How Children Learn Absolute Pitch',
  },
];

const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 4000,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 1200,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 992,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 1 },
    },
  ],
};

export default function PostSlider() {
  useEffect(() => {
    const followMouse = (e) => {
      const target = document.querySelector('.mouse-follow-target');
      if (target) {
        target.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
      }
    };

    window.addEventListener('mousemove', followMouse);

    return () => {
      window.removeEventListener('mousemove', followMouse);
    };
  }, []);

  return (
    <div className="slider-container">
      <Slider {...sliderSettings} className="cs-gap-24">
        {postData.map((item, index) => (
          <Div key={index} className="mouse-follow-target">
            <Post
              url={item.url}
              src={item.src}
              alt={item.alt}
              title={item.title}
            />
          </Div>
        ))}
      </Slider>
    </div>
  );
}
