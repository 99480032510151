import React from 'react';

export default function Post({ url, src, alt, title }) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div
        style={{
          width: '200px', // Fixed thumbnail width
          height: '200px', // Fixed thumbnail height
          overflow: 'hidden', // Ensures images don’t exceed bounds
          display: 'flex',
          marginLeft:'10px',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto', // Centers the thumbnail
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Crops the image to fill the dimensions
          }}
        />
      </div>
      <h4 style={{ textAlign: 'center', marginTop: '10px' }}>{title}</h4>
    </a>
  );
}
