import React from 'react';
import './Teamslider2.css';
import personalMessage from "../assets/Videos/personalMessage.mp4";

function TeamSlider2() {
    return (
        <div className="team-slider-container">
            <div className="team-slider">
                <div className="team-text-left">
                    <h2>CHILDREN</h2>
                    <h2>CAN</h2>
                    <h2>LEARN</h2>
                    <h2>SO</h2>
                    <h2>MUCH</h2>
                    <h2>MORE</h2>
                </div>
                <div className="team-text-right">
                    <h2>BEFORE</h2>
                    <h2>THEY</h2>
                    <h2>START</h2>
                    <h2>SCHOOL</h2>
                </div>

                <div className="team-video">
                    <video
                        style={{ width: '1200px' }}
                        src={personalMessage}
                        controls
                        autoPlay
                        muted
                        loop
                    ></video>
                </div>
                <div className="team-text-bottom">
                    <h2>Crafted with Passion & Purpose.</h2>
                    <p>A message from the system's creator!</p>
                </div>
            </div>

        </div>
    );
}

export default TeamSlider2;
