import React from 'react'
import './css/Fotter2.css'
import fb from '../assets/icons/facebook.png'
import twitter from '../assets/icons/twitter.png'
import LinkedIn from '../assets/icons/linkedin.png'
import inst from '../assets/icons/instagram.png'

function Footer2() {
    return (
        <div className='footer'>
            <div className="sb-footer-section-padding">
                <div className="sb-footer-links">
                    {/* <div className="sb-footer-links-div">
                    <h4>For Business</h4>
                    <a href="/employer">
                        <p>Employer</p>
                    </a>
                    <a href="/healthplan">
                        <p>Health Plan</p>
                    </a>
                    <a href="/individual">
                        <p>Individual</p>
                    </a>
                </div> */}
                    {/* <div className="sb-footer-links-div">
                    <h4>Resources</h4>
                    <a href="/employer">
                        <p>Resources Center</p>
                    </a>
                    <a href="/healthplan">
                        <p>Testimonials</p>
                    </a>
                    <a href="/individual">
                        <p>STV</p>
                    </a>
                </div> */}
                    {/* <div className="sb-footer-links-div">
                    <h4>C</h4>
                    <a href="/employer">
                        <p>Minute Magic</p>
                    </a>
                </div> */}
                    <div className="">
                        <p style={{ color: 'white',fontSize:'20px'  }}> Company: Absolute Pitch Incorporated Sugar Land Texas</p>
                    </div>
                    <div className="">
                        <p style={{ color: 'white', fontSize:'20px' }}> Contact:  absolutepitch2023@gmail.com</p>
                    </div>
                    <div className="sb-footer-links-div">
                        {/* <h4>Coming soon on</h4> */}
                        <div className="socialmedia">
                            <p><a href="https://www.facebook.com/profile.php?id=100090339619356"><img src={fb} alt='' /></a></p>
                            <p><a href="https://x.com/absolutepitch30"><img src={twitter} alt='' /></a></p>
                            <p><a href="https://www.linkedin.com/in/janice-romualdo-roskoph-53b69b18a/recent-activity/all/"><img src={LinkedIn} alt='' /></a></p>
                            <p><a href="https://www.instagram.com/absolute_pitch23/"><img src={inst} alt='' /></a></p>
                        </div>
                    </div>
                </div>
                <hr></hr>

                <div className="sb-footer-below">
                    <div className="sb-footer-copyright" style={{display:'flex', justifyContent:'space-around'}}>
                        <p style={{fontSize:'30px'}}>©{new Date().getFullYear()} Absolute Pitch Incorporated All Rights Reserved </p>
                        
                    </div>
                    <p style={{fontSize:'30px', color:'white'}}> PATENT PENDING 18121784 </p>
                    <div className="sb-footer-below-links">
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ba88e605-d5f4-4d1c-9510-3f77bd018455">
                            <div>
                                <p>Term & Conditions</p>
                            </div>
                        </a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f9e9e64d-c7d7-4477-888f-a044409db1aa">
                            <div>
                                <p>Privacy Policy</p>
                            </div>
                        </a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=3ec811fc-ea11-4eb9-a71f-5b437a0126db">
                            <div>
                                <p>Cookie Policy</p>
                            </div>
                        </a>
                        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ce7bd783-88bd-4d13-8fd4-05d9831993cc">
                            <div>
                                <p>Acceptable Use</p>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer2;