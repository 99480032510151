import React from 'react';
// import Slider from 'react-slick';
import Slider from 'react-slick/lib/slider'; // Import the full Slider component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Div from '../Div';
import './logolist.scss';

const partnerVideos = [
  {
    src: 'https://rumble.com/embed/v44yiow/?pub=2seqc6', // Embed video link
    alt: 'Partner 1',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v3snhn1/?pub=2seqc6', // Local video file
    alt: 'Partner 2',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v44v5rk/?pub=2seqc6',
    alt: 'Partner 3',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v44v4gx/?pub=2seqc6',
    alt: 'Partner 4',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v44u0ri/?pub=2seqc6',
    alt: 'Partner 5',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v44tyui/?pub=2seqc6',
    alt: 'Partner 6',
    title: '',
    isEmbed: true,
  },
  {
    src: 'https://rumble.com/embed/v44v3v8/?pub=2seqc6',
    alt: 'Partner 7',
    title: '',
    isEmbed: true,
  }
];

export default function LogoList() {
  const settings = {
    dots: true, // Show navigation dots
    arrows: true,
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of slides visible
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Autoplay the carousel
    autoplaySpeed: 2000, // Autoplay interval
    responsive: [
      {
        breakpoint: 768, // For small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Div className="cs-partner_video_wrap">
      <Slider {...settings}>
        {partnerVideos.map((partnerVideo, index) => (
          <div className="cs-partner_video" key={index}>
            {partnerVideo.isEmbed ? (
              // Embed video link using iframe
              <iframe
                src={partnerVideo.src}
                title={partnerVideo.alt}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  display: 'block', // Ensures iframe is displayed as a block element
                }}
                className="rumble-iframe"
              ></iframe>
            ) : (
              // Local video file
              <video controls style={{ width: '100%', height: '100%' }}>
                <source src={partnerVideo.src} type="video/mp4" />
                {partnerVideo.alt}
              </video>
            )}
            <h3 className="cs-video_title" style={{ textAlign: 'center', color: 'white' }}>
              {partnerVideo.title}
            </h3>
          </div>
        ))}
      </Slider>
    </Div>
  );
}
