// import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Team from '../Team';

const teamData = [
  {
    memberImage: '/images/NewMichaelJackson.gif',
    placeholderImage: '/images/NewMichaelJackson_static.jpeg',
    memberName: 'M. Jackson',

    memberSocial: {
      linkedin: '/',
      twitter: '/',
      youtube: '/',
      facebook: '/',
    },
  },
  {
    memberImage: '/images/NewMozart.gif',
    placeholderImage: '/images/NewMozart_static.jpeg',
    memberName: 'W. A. Mozart',

    memberSocial: {
      linkedin: '/',
      twitter: '/',
      youtube: '/',
      facebook: '/',
    },
  },
  {
    memberImage: '/images/NewChopin.gif',
    placeholderImage: '/images/NewChopin_static.jpeg',
    memberName: 'F. Chopin',

    memberSocial: {
      linkedin: '/',
      twitter: '/',
      youtube: '/',
      facebook: '/',
    },
  },
  {
    memberImage: '/images/newElton.gif',
    placeholderImage: '/images/newElton_static.jpeg',
    memberName: 'E. John',
    memberSocial: {
      linkedin: '/',
      twitter: '/',
      youtube: '/',
      facebook: '/',
    },
  },
  {
    memberImage: '/images/member_1.gif',
    placeholderImage: '/images/member_1_static.jpeg',
    memberName: 'Melon Bulgery',
    memberDesignation: 'Product Designer',
    memberSocial: {
      linkedin: '/',
      twitter: '/',
      youtube: '/',
      facebook: '/',
    },
  },
];

export default function TeamSlider() {
  /** State for managing GIF activation **/
  const [activeGifs, setActiveGifs] = useState([]);

  useEffect(() => {
    const timeouts = teamData.map((_, index) => {
      return setTimeout(() => {
        setActiveGifs((prev) => [...prev, index]);
      }, 3000 * (index + 1)); // 3-second delay per GIF
    });

    return () => {
      // Clear timeouts on cleanup
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  /** Slider Settings **/
  // const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  //   <div
  //     {...props}
  //     className={
  //       'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
  //     }
  //     aria-hidden="true"
  //     aria-disabled={currentSlide === 0 ? true : false}
  //   >
  //     <Icon icon="bi:arrow-left" />
  //   </div>
  // );

  // const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  //   <div
  //     {...props}
  //     className={
  //       'slick-next slick-arrow' +
  //       (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
  //     }
  //     aria-hidden="true"
  //     aria-disabled={currentSlide === slideCount - 1 ? true : false}
  //   >
  //     <Icon icon="bi:arrow-right" />
  //   </div>
  // );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    // prevArrow: <SlickArrowLeft />,
    // nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={
              activeGifs.includes(index)
                ? item.memberImage // Show GIF after delay
                : item.placeholderImage // Show static placeholder initially
            }
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
            memberSocial={item.memberSocial}
          />
        </Div>
      ))}
    </Slider>
  );
}
